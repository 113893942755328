// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-js": () => import("./../../../src/pages/investors.js" /* webpackChunkName: "component---src-pages-investors-js" */),
  "component---src-pages-participants-js": () => import("./../../../src/pages/participants.js" /* webpackChunkName: "component---src-pages-participants-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-team-team-detail-1-js": () => import("./../../../src/pages/team/team_detail1.js" /* webpackChunkName: "component---src-pages-team-team-detail-1-js" */),
  "component---src-pages-team-team-detail-2-js": () => import("./../../../src/pages/team/team_detail2.js" /* webpackChunkName: "component---src-pages-team-team-detail-2-js" */),
  "component---src-pages-team-team-detail-3-js": () => import("./../../../src/pages/team/team_detail3.js" /* webpackChunkName: "component---src-pages-team-team-detail-3-js" */),
  "component---src-pages-team-team-detail-4-js": () => import("./../../../src/pages/team/team_detail4.js" /* webpackChunkName: "component---src-pages-team-team-detail-4-js" */),
  "component---src-pages-team-team-detail-5-js": () => import("./../../../src/pages/team/team_detail5.js" /* webpackChunkName: "component---src-pages-team-team-detail-5-js" */),
  "component---src-pages-team-team-detail-6-js": () => import("./../../../src/pages/team/team_detail6.js" /* webpackChunkName: "component---src-pages-team-team-detail-6-js" */),
  "component---src-pages-team-team-detail-7-js": () => import("./../../../src/pages/team/team_detail7.js" /* webpackChunkName: "component---src-pages-team-team-detail-7-js" */),
  "component---src-pages-team-team-detail-8-js": () => import("./../../../src/pages/team/team_detail8.js" /* webpackChunkName: "component---src-pages-team-team-detail-8-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

